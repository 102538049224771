import React,{useState,useEffect} from "react"
import './../Styles/HomePage.css'
import { Container,Row,Col,Accordion } from 'react-bootstrap';
import bgColorService from './../Image/section-bg-sp.png'
import ImageAbouts from './../Image/about2-1.png'
import Card from './../Components/Card'
import TablePrice from './../Components/TablePrice'
import imgPortafolio from './../Image/temps-3-2048x1355.webp'
import disenoWeb from './../Image/diseno-web.webp'
import ImagePortfolio from './../Components/ImagePortfolio'
import Footer from './../Components/Footer'
import { Formik,Form,Field,ErrorMessage } from "formik";
import MenuComponent from './../Components/Menu'
import Arrabal from './../Image/arrabal.png'
import Asatis from './../Image/asatis-1.webp'
import Karina from './../Image/KARINA.webp'
import Geon from './../Image/GEON.png'
import Arquitectura from './../Image/arquitectura.webp'
import CafeDelMar from './../Image/cafe-del-mar-1.webp'
import Cellux from './../Image/cellux.webp'
import Dcaro from './../Image/DCARO.webp'

const project = {
		'categories' : [
				{
					id:0,
					desc:'Todas'
				},
				{
					id:1,
					desc:'Landing Page'
				},
				{
					id:2,
					desc:'Básica'
				},
				{
					id:3,
					desc:'Corporativa'
				},
				{
					id:4,
					desc:'Ecommerce'
				},
		],
		'projects' : [
				{
					id:1,
					category:1,
					name:'ARRABAL GASTROBAR',
					url:Arrabal
				},
				{
					id:2,
					category:1,
					name:'CAFÉ DEL MAR',
					url:CafeDelMar
				},
				{
					id:3,
					category:2,
					name:'GRUPO BRIEVA ARQUITECTURA',
					url:Arquitectura
				},
				{
					id:4,
					category:2,
					name:'ASATIS IPS',
					url:Asatis
				},
				{
					id:5,
					category:3,
					name:'DCARO SOLUTIONS',
					url:Dcaro
				},
				{
					id:6,
					category:3,
					name:'GEÓN CONSTRUCCIONES',
					url:Geon
				},
				{
					id:7,
					category:4,
					name:'KARINA MENTORA ESPIRITUAL',
					url:Karina
				},
				{
					id:8,
					category:4,
					name:'CELLUX',
					url:Cellux
				},
		]
	}



const Inicio = () => {

	const [listPortfolio,setPortfolio] = useState([])
	const [statePortfolio,setStatePortfolio] = useState(false)

	useEffect(() => {
		if(!statePortfolio){
			filterPortolio()
			setStatePortfolio(true)
		}
	})
	
	const filterPortolio = (idCategory =  0) => {
		if(idCategory == 0){
			setPortfolio(project.projects)
			return
		}
		const r = project.projects.filter((item) => item.category == idCategory )
		setPortfolio(r)
	}

	const enviarForm = (values) => {
		console.log(values)
		return false
	}

	return (
		<>
			<MenuComponent />
			<section className="homePortada">
				<div className="text-vertical"> 
					<div>
						<div className="rrss">
							<i className="fa-brands fa-facebook-f text-white"></i>
						</div>
						<div className="rrss">
							<i className="fa-brands fa-instagram text-white"></i>
						</div>
						<div className="rrss">
							<i className="fa-brands fa-whatsapp text-white"></i>
						</div>
					</div>
					<div className="text-rotate">
						Siguenos <div></div>
					</div>
				</div>
				<div id="sloganText">
					<h5 className="text-white">HAZ REALIDAD TU SUEÑO DIGITAL</h5>
					<h1 className="text-white">AGENCIA DIGITAL <br /> DE  <span className="text-gr"> DISEÑO WEB </span></h1>
					<button className="slide">Leer más</button>
				</div>
			</section>
			<section className="sectionService" id="services">
				<Container>
					<Row>
						<Col xs={12} lg={6} className="position-relative">
							<img className="vector-service" src={bgColorService} alt="" />
							<h3 className="title-service">SOMOS EXPERTOS EN <br />  <span className="text-gr"> DISEÑO WEB </span></h3>
						</Col>
						<Col xs={12} lg={6}>
							<p>Nuestro equipo de trabajo hace realidad sus ideas creando páginas web atractivas para sus visitantes.  Combinamos el diseño de su página web con estrategias de marketing digital para que su empresa sea más visible en internet.</p>
						</Col>
					</Row>
					<Row>
						<Col>
							<Card title="DESARROLLO DE SITIOS WEB" description="Diseñamos la Web Corporativa de tu negocio: inmobiliarias, librerías, logística, reformas, construcción, farmacia, psicología, escuelas infantiles, academias…" icon={<i className="fa-solid fa-file-pen"></i>}></Card>
						</Col>
						<Col>
							<Card title="DISEÑO DE INTERFAZ DE APLICACIONES" description="Esta es su solución si necesita reducir los costes o los plazos de su proyecto para tener su aplicación lista lo antes posible." icon={<i className="fa-solid fa-mobile-screen"></i>}></Card>
						</Col>
						<Col>
							<Card title="SOPORTE Y MANTENIMIENTO" description="Mantén tu negocio online actualizado y a salvo de bucaneros." icon={<i className="fa-solid fa-gears"></i>}></Card>
						</Col>
						<Col>
							<Card title="DISEÑO DE LA BASE DE DATOS DE LA IU" description="Nuestro objetivo es crear un diseño significativo y centrado en el usuario ." icon={<i className="fa-brands fa-figma"></i>}></Card>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="sectionAbouts" id="abouts">
				<Container>
					<Row>
						<Col xs={12} xl={6}>
							<img src={ImageAbouts} className="img-fluid" id="img-about" alt="" />
						</Col>
						<Col xs={12} xl={6}>
							<div className="position-relative p-3 mt-5" id="text-encab">
								<img className="vector-service-abouts-two" src={bgColorService} alt="" />
								<div className="title-service">
									<h4 className="text-white">SOBRE NOSOTROS</h4>
									<h3 className="title-service text-white"><b> EXPERTOS EN  <span className="text-gr"> DISEÑO WEB </span> </b></h3>
								</div>
							</div>
							<p className="text-white p-3">
								Somos garantes de páginas web de calidad tomando en cuenta todas las especificaciones de nuestros clientes. Todos nuestros planes cuentan con diseño y 
								tecnología de punta, para que cumplan todas las condiciones que se requieren hoy en día.
							</p>
							<ul className="text-white p-3">
								<li>
									<i className="fa-solid fa-circle-check text-gr mr-2"></i>
									Puntualidad en las entregas de los proyectos</li>
								<li>
									<i className="fa-solid fa-circle-check text-gr mr-2"></i>
									Diseños personalizados al gusto de nuestros clientes</li>
								<li>
									<i className="fa-solid fa-circle-check text-gr mr-2"></i>
									Mejores precios con mayor calidad</li>
							</ul>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="sectionPlans">
				<h1 className="text-center text-gr">
					Nuestros planes de Páginas Web
				</h1>
				<Container>
					<Row>
						<Col lg={3}>
							<TablePrice 
								service="Landing Page"
								desc="Página de aterrizaje"
								price="999.000"
								recommended={false}
								attrs={
									[
										{desc:'WordPress'},
										{desc:'Landing Page / One Page Site'},
										{desc:'5 bloques de navegación'},
										{desc:'Hosting y Dominio (gratis x 1 año)'},
										{desc:' Desarrollo basado en plantilla'},
										{desc:'Vinculación Redes Sociales'},
										{desc:'Certificado seguridad SSL'},
										{desc:'Diseño responsive'},
										{desc:'Botón de WhatsApp'},
									]
								}
								days="4"
								url='https://wa.link/q59hsk'
							/>
						</Col>
						<Col lg={3}>
							<TablePrice 
								service="BÁSICA"
								desc="Página informativa"
								price="1.299.000" 
								recommended={false}
								attrs={
									[
										{desc:'WordPress'},
										{desc:'Hasta 5 páginas internas'},
										{desc:'Hosting y Dominio (gratis x 1 año)'},
										{desc:' Desarrollo basado en plantilla'},
										{desc:'Vinculación Redes Sociales'},
										{desc:'Blog de Noticias o Eventos'},
										{desc:'Capacitación de la Página Web'},
										{desc:'Certificado seguridad SSL'},
										{desc:'Diseño responsive'},
										{desc:'Botón de WhatsApp'},
									]
								}
								days="7"
								url='https://wa.link/zvdlrc'
							/>
						</Col>
						<Col lg={3}>
						<TablePrice 
								service="CORPORATIVA"
								desc="Portal para tus clientes"
								price="1.599.000" 
								recommended={true}
								attrs={
									[
										{desc:'WordPress'},
										{desc:'Hasta 8 páginas internas'},
										{desc:'Hosting y Dominio (gratis x 1 año)'},
										{desc:'Portafolio de Servicios'},
										{desc:'Vinculación Redes Sociales'},
										{desc:'Blog de Noticias o Eventos'},
										{desc:'Capacitación de la Página Web'},
										{desc:'Certificado seguridad SSL'},
										{desc:'Diseño responsive'},
										{desc:'Botón de WhatsApp'},
									]
								}
								days="7"
								url='https://wa.link/2fznrt'
							/>
						</Col>
						<Col lg={3}>
						<TablePrice 
								service="ECOMMERCE"
								desc="Tienda online"
								price="2.499.000" 
								recommended={false}
								attrs={
									[
										{desc:'WordPress'},
										{desc:'Hasta 14 páginas internas'},
										{desc:'Hosting y Dominio (gratis x 1 año)'},
										{desc:'Diseño Personalizado – Avanzado'},
										{desc:'Portafolio de Servicios'},
										{desc:'Carga de hasta 30 productos y/o servicios'},
										{desc:'Vinculación Redes Sociales'},
										{desc:' Integración de pasarelas de pago'},
										{desc:'Blog de Noticias o Eventos'},
										{desc:'Capacitación de la Página Web'},
										{desc:'Carrito de compras'},
										{desc:'Certificado seguridad SSL'},
										{desc:'Diseño responsive'},
										{desc:'Botón de WhatsApp'},
									]
								}
								days="21"
								url='https://wa.link/cvmp6o'
							/>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="" id="includePlans">
				<div className="divider-plans">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
						<path className="elementor-shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
						c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
						c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
					</svg>
				</div>
			</section>
			<section className="detailsPlans" id="">
				<Container>
					<Row>
						<Col xs={12} lg={6}>
							<h3>Todos nuestros planes incluyen</h3>
							<hr />
							<ul className="">
								<li>
									<i className="fa-solid fa-circle-check text-gr mr-2"></i>
									Hosting y Dominio (gratis x 1 año)
								</li>
								<li>
									<i className="fa-solid fa-circle-check text-gr mr-2"></i>
									Garantía de por errores
								</li>
								<li>
									<i className="fa-solid fa-circle-check text-gr mr-2"></i>
									Diseño Responsive (adaptable a Smartphones y Tablets)
								</li>
								<li>
									<i className="fa-solid fa-circle-check text-gr mr-2"></i>
									Entregas a Tiempo
								</li>
								<li>
									<i className="fa-solid fa-circle-check text-gr mr-2"></i>
									Soporte vía Email sin costo Adicional
								</li>
								<li>
									<i className="fa-solid fa-circle-check text-gr mr-2"></i>
									Certificado seguridad SSL
								</li>
							</ul>
						</Col>
						<Col xs={12} lg={6}>
							<img src={imgPortafolio} className="img-fluid" alt="" />
						</Col>
					</Row>
				</Container>
			</section>
			<section className="typeWebSite">
				<div className="overlayContainer"></div>
				<div className="elementor-shape elementor-shape-top" data-negative="false">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
						<path className="elementor-shape-fill" opacity="0.33" d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"></path>
						<path className="elementor-shape-fill" opacity="0.66" d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path>
						<path className="elementor-shape-fill" d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path>
					</svg>
						
				</div>
				<div  className="contentDesc">
					<Container>
						<Row>
							<Col>
								<div className="text-center text-white ">
									<img src={disenoWeb} className="w-25 d-block m-auto" alt="" />
									<h3> ¿Qué tipo de web necesitas? </h3>
									<p>Puede que tengas ya un negocio o que tengas en mente el proyecto de tu vida. Tener un sitio web hoy en día, es imprescindible. Si quieres poner en marcha lo que llevas tanto tiempo pensando, tienes que conocer todo lo que podemos hacer por ti.</p>
									<ul className="list-service">
										<li><i className="fa-solid fa-circle-check text-gr mr-2"></i> Tienda online</li>
										<li><i className="fa-solid fa-circle-check text-gr mr-2"></i> Blog personal</li>
										<li><i className="fa-solid fa-circle-check text-gr mr-2"></i> Web Corporativa</li>
										<li><i className="fa-solid fa-circle-check text-gr mr-2"></i> Inmobiliaria</li>
										<li><i className="fa-solid fa-circle-check text-gr mr-2"></i> Web Informativa</li>
										<li><i className="fa-solid fa-circle-check text-gr mr-2"></i> Portafolio</li>
									</ul>
									<button className="slide-contact">CONTACTAR UN ASESOR</button>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</section>
			<section className="portfolio">
				<div className="diviser-proyects">
					<span>
						PROYECTOS
					</span>
				</div>
				<div className="text-center text-portolio">
					<h1 className="">ALGUNOS DE NUESTROS  <span className="text-gr"> TRABAJOS REALIZADOS </span></h1>
				</div>
				<div className="has_filter_project text-center">
					{ project.categories.map((item,index) => <button key={index} className="tabProjects" onClick={() => filterPortolio(item.id) } >{ item.desc }</button>) }
					<ImagePortfolio list={listPortfolio} /> 
				</div>
			</section>
			<section className="staticts">
				<Container>
					<Row>
						<Col>
							<div className="text-center content-statics">
								<i className="fa-solid fa-laptop text-white"></i>
								<h1 className="text-white"><b>+300</b></h1>
								<p>Proyectos completados con éxito</p>
							</div>
						</Col>
						<Col>
							<div className="text-center content-statics">
								<i className="fa-solid fa-user text-white"></i>
								<h1 className="text-white"><b>+280</b></h1>
								<p>Empresas que han confiado en nosotros</p>
							</div>
						</Col>
						<Col>
							<div className="text-center content-statics">
								<i className="fa-solid fa-trophy text-white"></i>
								<h1 className="text-white"><b>7</b></h1>
								<p>Años de experiencia en diseño web</p>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="contact position-relative">
				<Container>
					<Row>
						<Col>
							<Formik
							initialValues={{
								name:'',
								email:'',
								phone:'',
							}}
							onSubmit={
								async values => enviarForm(values)
							}
							>
								<Form>
									<div className="content-form mb-5">
										<div className="encab_form">
											<h5 className="text-white"><b> Contacto </b></h5>
											<h2 className="text-white">SIÉNTASE LIBRE DE CONTACTAR A NUESTRA AGENCIA</h2>
										</div>
										<div className="form-contact">
											<Field name="name" placeholder="Nombre Completo"/>
											<Field name="email" placeholder="Correo electronico"/>
											<Field name="phone" placeholder="Teléfono"/>
											<Field as="textarea" name="message" placeholder="Cuentanos como te ayudaremos" />
											<button className="btn btn-gr">Enviar <i className="fa-solid fa-paper-plane"></i></button>
										</div>
									</div>
								</Form>
							</Formik>
						</Col>
						<Col>
							<div className="pqrs-collapse">
								<h5 className="text-gr"><b> Preguntas frecuentes </b></h5>
								<h2 className="text-dark">¿CÓMO TE PODEMOS  <span className="text-gr"> AYUDAR?</span></h2>
								<Accordion defaultActiveKey="0">
									<Accordion.Item eventKey="0">
										<Accordion.Header>¿El dominio y hosting sólo es por un año?</Accordion.Header>
										<Accordion.Body>
											Si, solo es por un año. Al transcurrir este tiempo el hosting y dominio debe ser renovado con nuestra agencia.
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="1">
										<Accordion.Header>¿Por qué es importante tener una página web?</Accordion.Header>
										<Accordion.Body>
											Es en la actualidad uno de los medios de comunicación más utilizados por las masas, y es relevante para entender que vamos a encontrar todo lo que deseemos a través de este medio. Tu negocio debe tener presencia digital y una de las mejores formas de hacerlo es a través de una página web en la que los usuarios consigan toda la información que buscan sobre el servicio que ofreces.
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="3">
										<Accordion.Header>¿Qué beneficios tiene confiar en AGENCIA GRUPO RINCON?</Accordion.Header>
										<Accordion.Body>
											<ol>
												<li>Recibirás una página web de calidad con garantía de soporte ante cualquier irregularidad.</li>
												<li>Diseños personalizados con los requerimientos de cada cliente en particular.</li>
												<li>Precios acorde a el presupuesto de cada cliente.</li>
												<li>&nbsp;Entregas puntuales de los proyectos a realizar.</li>
											</ol>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="4">
										<Accordion.Header>¿Qué diferencia hay entre hosting, alojamiento y dominio?</Accordion.Header>
										<Accordion.Body>
											<p>El hosting o alojamiento, son sinónimos, es el ordenador donde estarán todas las imágenes y textos (entre otros elementos) que se mostrarán en tu sitio web.
											El dominio es el nombre de tu página web, en este caso nuestro dominio es «agenciagruporincon.com ». Existen varios tipos de dominios en función de su terminación: .es, .com, .net, .edu…</p>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="5">
										<Accordion.Header>¿Qué es el diseño web responsive?</Accordion.Header>
										<Accordion.Body>
											<p>El diseño web responsive o diseño web adaptativo es una técnica de diseño web que busca presentar correctamente los contenidos de un sitio web en todos los dispositivos (ordenadores de escritorio, Tablet, teléfono).
											Independientemente de que se visite la web desde un ordenador, portátil, Tablet o teléfono móvil, el diseño web responsive hará que el contenido se visualice correctamente.</p>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="6">
										<Accordion.Header>¿Puedo gestionar y actualizar todos los contenidos de mi página web?</Accordion.Header>
										<Accordion.Body>
											<p>Si, pero… es preferible que se indiquen los cambios de contenidos a realizar y seamos nosotros o terceras personas, expertos en la plataforma, los que realicen los cambios.  <br />
											Algunos de los motivos para actualizarla son: <br />
											1.Mantener el diseño web responsive y asegurar que la información siempre se vea correctamente en todos los dispositivos. <br />
											2.No perder el diseño realizado al no tener nociones de maquetación web. <br />
											3.Preservar las funcionalidades de la web. <br />
											4.Evitar actualizaciones no planificadas y que podrían alterar el funcionamiento de la web. <br />
											</p>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="7">
										<Accordion.Header>¿El mantenimiento es obligatorio?</Accordion.Header>
										<Accordion.Body>
											<p>No, aunque es muy recomendable tener mantenimiento web para garantizar el buen funcionamiento web.</p>
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="footer">
				<Footer />
			</section>
		</>
	)
}

export default Inicio