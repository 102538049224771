import React from 'react'
import './../Styles/Card.css'

const Card = ({ title,description,icon }) => {
	return (
		<>
			<div className="card-home">
				<div className="icon-service">
					{icon}
				</div>
				<div className="card-body">
					<h5 className="title-body">
						{ title }
					</h5>
					<p>{ description }</p>
				</div>
			</div>
		</>
	)
}

export default Card