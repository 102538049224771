import React from 'react';
import './../Styles/ImagePortfolio.css'

const ImagePortfolio = ({list}) => {
	console.log(list)
	return (
		<>
			<div className="position-relative">
				<div className="galery-filter-porfolio-container">
					{
						list.map((el,index) => 
							<div key={index} className="containerPortfolio">
								<div className="caption">{ el.name }</div>
								<img src={el.url}  alt={el.name} />	
							</div>
						)
					}
				</div>
			</div>
		</>
	)
}

export default ImagePortfolio