import React from 'react'
import './../Styles/TablePrice.css'
import check from './../Image/icons8-check-64.png'

const TablePrice = ({...props}) => {
	return (
		<>
			<div className="table-price position-relative">
				{
					props.recommended 
					? <div className="elementor-price-table__ribbon elementor-ribbon-right">
							<div className="elementor-price-table__ribbon-inner">
								Recomendado				
							</div>
						</div>
					: '' 
				}
				
				<div className={"header-table text-center " + ( props.recommended ? "recommended" : 'classic')}>
					<h1><b>{ props.service }</b></h1>
					<p>{ props.desc }</p>
				</div>
				<div className="amount-price">
					<span className="symbol-price">$</span>
					<span className="desc-service">{ props.price }</span>
					<div className="cod-price">cop</div>
				</div>
				<div className="body-price">
					<ul>
						{
							props.attrs.map(attr => 
							<li key={attr.desc}><img src={check} className="check" alt="" /> {attr.desc} </li>
						)}
						
					</ul>
					<div className="text-center">
						<a href={props.url} target="_blank" className="btn btn-gr-secondary text-white quote">Cotizar</a>
						<p>Entrega { props.days } días</p>
					</div>
				</div>
			</div>
		</>
	)
}

export default TablePrice