import { getInfoCurrency } from "../Shared/Services/apiCurrency"

export const TableInvoice = ({invoices}) => {
    console.log("🚀 ~ file: TableInvoice.jsx:4 ~ TableInvoice ~ invoices:", invoices)
    const initCheck = async (amount, info) => {
        console.log("🚀 ~ file: Checkout.jsx:9 ~ initCheck ~ info:", info)
        let valueEnd = amount
        if(info.currency !== undefined){
            const { value } = await getInfoCurrency()
            valueEnd = (value * amount).toFixed(0)
        }
        var checkout = new window['WidgetCheckout']({
            currency: 'COP',
            amountInCents: valueEnd * 100,
            reference: 'AD002901221',
            publicKey: 'pub_prod_vpOB8kqdvOpZiwWZOCBZOXgsIH6j8OMp',
        })

        checkout.open((result) => {
            console.log("🚀 ~ file: Checkout.jsx:29 ~ initCheck ~ result", result)
        })
        console.log("🚀 ~ file: Checkout.jsx:30 ~ initCheck ~ checkout:", checkout)
    }
    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('de-DE').format(amount)
    }
    return (
        <div className="container-fluid animate__animated animate__fadeInUp">
            <div className="card mt-3">
                <div className="card-body">
                    <h3 className="text-left">Facturas</h3>
                    <div className="table-responsive">
                        <table className="table table-borderless table-striped">
                            <thead className="table-light">
                                <tr>
                                    <th>Cliente</th>
                                    <th>identificación</th>
                                    <th>total</th>
                                    <th>total pagado</th>
                                    <th>Acción</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    invoices.length > 0 && invoices.map((el, index) => (
                                        <tr key={index}>
                                            <td className="fs-6">{el.client.name}</td>
                                            <td className="fs-6">{el.client.identification}</td>
                                            <td className="fs-6">{ el.currency ? el.currency.symbol : '$' } { formatCurrency(el.total) }</td>
                                            <td className="fs-6">{ el.currency ? el.currency.symbol : '$' } { formatCurrency(el.totalPaid) }</td>
                                            <td className="fs-6">
                                                <div className="">
                                                    <a href="#" className="text-success d-flex justify-content-start align-items-center" onClick={() => initCheck(el.total, el)}>
                                                        <span>Pagar</span> 
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}