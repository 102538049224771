import { credentials } from "../Enviroment/enviroment"

export const getInfoInvoice = async (identification) => {
    return await fetch(`${credentials.alegra.url}invoices?client_identification=${identification}`, {
        method: 'GET',
        headers: {
            accept: 'application/json',
            authorization: `Basic ${btoa(`${credentials.alegra.user}:${credentials.alegra.token}`)}`
        }
    })
}