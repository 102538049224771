import React,{useState} from 'react'
import { Navbar,Container,Nav,Offcanvas,Button } from 'react-bootstrap'
import Logo from './../Image/grupo-rincon-white.png'
import LogoBlack from './../Image/grupo-rincon-black.png'
import MenuIcon from './../Image/menu.png'
import './../Styles/MenuComponents.css'


const Menu = () => {
	const [navbar,setNabvar] = useState(false)
	const changeBackground = () => {
		if(window.scrollY > 400){
			setNabvar(true)
		}else{
			setNabvar(false)
		}
	}
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	window.addEventListener('scroll', changeBackground)
	return (
		<div>
			<header className="">
				<Navbar fixed="top" expand="lg" bg={navbar ? 'gr' : ''}>
					<Container className="menuLink">
						<Navbar.Brand href="#">
							<img className="img-fluid logo" src={Logo} alt="" />
						</Navbar.Brand>
						<Nav className="d-flex menu-desktop">
							<Nav.Link className="text-white linkMenu" href="#home">Inicio</Nav.Link>
							<Nav.Link className="text-white linkMenu" href="#abouts">Nosotros</Nav.Link>
							<Nav.Link className="text-white linkMenu" href="#services">Servicios</Nav.Link>
							<Nav.Link className="text-white linkMenu" href="#portafolio">Portafolio</Nav.Link>
							<Nav.Link className="text-white linkMenu" href="#contact">Contacto</Nav.Link>
							<Nav.Link className="text-white linkMenu highlight-payment" href="/checkout">Pagar mi factura</Nav.Link>
						</Nav>
						<Button variant="primary" onClick={handleShow} className="me-2 btn-mobile">
							<img src={MenuIcon} width="10" alt="icono-menu" />
						</Button>
					</Container>
				</Navbar>
				<Offcanvas placement={'end'} show={show} onHide={handleClose}>
					<Offcanvas.Header closeButton>
					<Offcanvas.Title>
						<img className="img-fluid logo" src={LogoBlack} alt="" />
					</Offcanvas.Title>
					</Offcanvas.Header>
					<Offcanvas.Body>
						<Nav className="nav-mobile">
							<Nav.Link className="text linkMenu"  href="#home" onClick={() => setShow(false)}>Inicio</Nav.Link>
							<Nav.Link className="text linkMenu"  href="#abouts" onClick={() => {
								setTimeout(() => {
									setShow(false)
								}, 2000);
							}}>Nosotros</Nav.Link>
							<Nav.Link className="text linkMenu"  href="#services" onClick={() => setShow(false)}>Servicios</Nav.Link>
							<Nav.Link className="text linkMenu"  href="#portafolio" onClick={() => setShow(false)}>Portafolio</Nav.Link>
							<Nav.Link className="text linkMenu"  href="#contact" onClick={() => setShow(false)}>Contacto</Nav.Link>
						</Nav>
					</Offcanvas.Body>
				</Offcanvas>
			</header>
		</div>
	)
}

export default Menu