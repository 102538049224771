export const getInfoCurrency = async (amount) => {
    const response = await fetch(`https://api.currencyapi.com/v3/latest?apikey=XqSQUB59x451D67a5ViLtBgaSv6W1wBjECxWr34T&currencies=COP`, {
        method: 'GET',
        headers: {
            accept: 'application/json',
        }
    })
    if(response.ok){
        const currency = await response.json()
        return {
            currency: currency.data.COP.code,
            value: currency.data.COP.value
        }
    }
}

