import React from 'react'
import { Container,Row,Col,Accordion } from 'react-bootstrap';
import Logo_footer from './../Image/grupo-rincon-grey.png'
import './../Styles/Footer.css'
import { Mail,Smartphone,Facebook,Twitter,Linkedin } from 'react-feather';

const date = new Date();
const years = date.getFullYear()

const Footer = () => {
	return (
		<>
			<footer>
				<Container>
					<Row>
						<Col>
							<img src={Logo_footer} className="w-50" alt="" />
						</Col>
						<Col>
							<div className="footerRs">
								<a className="text-dark" href="https://www.facebook.com/gruporincon.co" target="_blank" title="Facebook">
									<span>
										<Facebook className="color-icon-white" size={18} />
									</span>
								</a>
								<a className="text-dark" href="https://www.instagram.com/gruporincon.co/" target="_blank" title="Instagram">
									<span>
										<Twitter className="color-icon-white" size={18} />
									</span>
								</a>
								<a className="text-dark" href="https://www.linkedin.com/in/agencia-digital-grupo-rincon-b33514121/" target="_blank" title="Linkedin">
									<span>
										<Linkedin className="color-icon-white" size={18} />
									</span>
								</a>
							</div>
						</Col>
						<Col>
							<a className="text-dark" href="https://api.whatsapp.com/send?phone=3122539123&amp;text=Hola,%20quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre:">
								<span>
								<Smartphone className="color-icon-grey" size={24} />
								</span>
								<span className="elementor-icon-list-text">(+57) 3122539123</span>
							</a>
						</Col>
						<Col>
							<a className="text-dark" href="mailto:contacto@agenciagruporincon.com">
								<span>
									<Mail className="color-icon-grey" size={24} />
								</span>
								<span className="elementor-icon-list-text">contacto@gruporincon.com.co</span>
							</a>
						</Col>
					</Row>
				</Container>
			</footer>
			<div className="copyright">
				<span>© { years } Diseñado y desarrollado por Agencia Grupo Rincon</span>
			</div>
		</>
	)
}

export default Footer