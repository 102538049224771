import { useState } from "react"
import { getInfoInvoice } from "../Shared/Services/apiAlegra"
import '../Styles/Checkout.css'
import { TableInvoice } from "../Components/TableInvoice"
import 'animate.css';

export const Checkout = () => {
    const [invoices, setInvoice] = useState([])
    const [client, setClient] = useState({})
    const [loading, setLoading] = useState(false)

    const getInfoClient = async () => {
        setLoading(true)
        const info = await getInfoInvoice(client)
        if(info.ok){
            const data = await info.json()
            setInvoice(data)
        }
        setLoading(false)
    }
    return (
        <div>
            <div className={`container-fluid ${invoices.length === 0 ? 'screen-full' : '' }`} id="background-checkout">
                <div className={`bg-white p-5 card-search-client shadow-sm flex flex-column animate__animated animate__fadeInUp `}>
                    <img src={require('./../Image/grupo-rincon-black.png')} alt="logo-de-grupo-rincon" className="img-fluid w-100" />
                    <label htmlFor="client">Identificación del cliente</label>
                    <input type="text" placeholder="123456789" id="client" onChange={(e) => setClient(e.target.value)} className="form-control form-control-sm "/>
                    <div>
                        <small className="text-muted">Escribe el documento de identificación de tu empresa para mostrarte tus cuentas con nosotros</small>
                    </div>
                    {
                        loading 
                            ? 
                            <button class="btn btn-sm btn-primary m-0" type="button" disabled>
                                <span class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
                                <span> Buscando</span>
                            </button>
                            : <button className="btn btn-sm btn-primary m-0" onClick={() => getInfoClient()}>Buscar</button>
                    }
                </div>    
            </div>
            
            {
                invoices.length > 0 && <TableInvoice invoices={invoices} /> 
            }
            
        </div>
    )
}